const storage: any = null

const utils = {
  curLang: 'zh-hk', // 获取当前设置的语种
  storageKey: '', // 机票的缓存id
  storage, // 机票的缓存数据
  curVersion: '20230712', // 版本
  serverTimeDiff: 0, // 服务器和本地的时间差
  iflightUrl(state = false): string {
    let orn = location.origin
    try {
      const env = vueApp.getCurrentInstance().$config.public.envData.VITE_PACK_ENV
      if (env === 'dev') {
        // orn = 'https://www.t.hopegoo.com'
        orn = 'https://www2.t.hopegoo.com'
      }
      else if (/\.t\./.test(orn)) {
        // 机票测试环境
        orn = 'https://www2.t.hopegoo.com'
      }
    }
    catch (e) {}

    return `${orn}/${this.curLang}`
  },
  init() {
    const { locale, getLocaleCookie } = vueApp.getCurrentInstance().$i18n
    this.curLang = locale || 'zh-hk'
    this.storageKey = `intl_flight_${this.curLang}_2023`
    this.getStorage()
  },
  getStorage(): any {
    // 初始数据
    if (process.client) {
      const initData = {}
      this.storage = window.localStorage[this.storageKey] // 缓存数据
      initData.version = this.curVersion
      this.storage = this.storage ? JSON.parse(this.storage) : initData
      if (this.storage.version !== this.curVersion) { this.storage = initData } // 缓存有问题 更新版本 重置缓存
      return this.storage
    }
  },
  setStorage() {
    if (process.client && this.storage) {
      window.localStorage[this.storageKey] = JSON.stringify(this.storage)
    }
  },
  // 获取固定格式的日期
  getFormatDate(date = new Date(Date.now() + this.serverTimeDiff)): object {
    let language = this.curLang
    const languageMap = {
      'en-us': {
        week: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        month: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      },
      'zh-hk': {
        week: ['週日', '週一', '週二', '週三', '週四', '週五', '週六'],
        month: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
      },
      'zh-tw': {
        week: ['週日', '週一', '週二', '週三', '週四', '週五', '週六'],
        month: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
      },
      'ja-jp': {
        week: ['日', '月', '火', '水', '木', '金', '土'],
        month: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
      },
    }
    language = languageMap[language] ? language : 'en-us'

    const today = new Date(Date.now() + this.serverTimeDiff)
    today.setHours(0, 0, 0, 0)
    date = new Date(date)
    date.setHours(0, 0, 0, 0)

    const version = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
    const week = languageMap[language].week[date.getDay()]
    const enMonth = languageMap[language].month[date.getMonth()]
    let iDate: any = date.getDate()
    if (this.curLang === 'zh-hk' || this.curLang === 'zh-tw') {
      if (parseInt(String(iDate)) < 10) {
        iDate = `0${iDate.toString()}`
      }
    }
    return {
      date: [date.getFullYear(), date.getMonth() + 1, iDate],
      day: `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`,
      day_s: `${date.getFullYear()}-${((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1)}-${(date.getDate() < 10 ? '0' : '') + date.getDate()}`,
      week,
      version,
      enMonth,
    }
  },
  typeOfDate(date): boolean {
    return Object.prototype.toString.call(date) === '[object Date]'
  },
  // 日历可选结束日期
  endDate(): any {
    const date = new Date(Date.now() + this.serverTimeDiff)
    date.setHours(0, 0, 0, 0)
    date.setFullYear(date.getFullYear() + 1) // 一年
    return date
  },
  today(): any {
    const date = new Date(Date.now() + this.serverTimeDiff)
    return date
  },
  threeMonthAfterToday(today?: Date, months = 3): any {
    today = today || this.today()
    today!.setHours(0, 0, 0, 0)
    today!.setMonth(today!.getMonth() + months)
    return today
  },
  getCityCode(city = {}): any {
    return city.PortCode || city.CityCode
  },
  getCityName(city = {}): any {
    return city.CityNameX || city.CityName
  },
  stopClick(bindDom: any) {
    bindDom.forEach((item, index) => {
      const dom = document.querySelectorAll(`.flight-content ${item}`)
      dom.forEach((n, m) => {
        n.addEventListener('click', (e) => {
          utils.stopFunc(e)
        })
        // n.onclick = function (e) {
        //   e = e || event;
        //   utils.stopFunc(e);
        // };
      })
    })

    // for (let i = 0; i < bindDom.length; i++) {
    //   bindDom[i].onclick = function (e) {
    //     e = e || event;
    //     utils.stopFunc(e);
    //   };
    // }
  },
  stopFunc(e: any) {
    e.stopPropagation ? e.stopPropagation() : e.cancelBubble = true
  },
  tc(key: string): string {
    const t = vueApp.getCurrentInstance().$t
    return t(`home.${key}`)
  },
  unitConverter(num: any): string {
    if (!num || isNaN(num)) {
      return ''
    }
    num = Number(num)
    if (utils.curLang.startsWith('zh')) {
      if (Math.abs(num) > 10000) {
        return `${parseFloat((num / 10000).toFixed(1))}萬`
      }
      else {
        return `${parseFloat(num.toFixed(0))}`
      }
    }
    else {
      if (Math.abs(num) > 1000000) {
        return `${parseFloat((num / 1000000).toFixed(1))}mil`
      }
      else if (Math.abs(num) > 1000) {
        return `${parseFloat((num / 1000).toFixed(1))}k`
      }
      else {
        return `${parseFloat(num.toFixed(0))}`
      }
    }
  },
}

vueApp.ready(() => {
  utils.init()
})

export default utils
